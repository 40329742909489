import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../../../context/services/context"

const stateKey = "enrollmentState"

export const initialState = {
  documents: [],
  uploadProofOfDiagnosis: [],
  uploadLaboratoryResults: [],
  agreeToMechanics: [],
  consentAndAuthorization: [],
  isDiagnosedWithHypertension: [],
  allowAccessToResults: [],
  rightToTerminate: [],
  consentViber: [
    `I agree to join the Best Life Viber community to get regular updates on the program’s benefits. <span style="color:#777"><i>(Optional)</i></span>`,
  ],
  activeCode: {},
}

const forms = {
  SAVE_EMPLOYEE_INFORMATION: "SAVE_EMPLOYEE_INFORMATION",
  RESET_DETAILS: "RESET_DETAILS",
  SAVE_AGREE_CONSENT_ENROLLMENT: "SAVE_AGREE_CONSENT_ENROLLMENT",
  SAVE_AGREE_WITH_HYPERTENSION: "SAVE_AGREE_WITH_HYPERTENSION",
  SAVE_AGREE_ALLOW_ACCESS: "SAVE_AGREE_ALLOW_ACCESS",
  SAVE_AGREE_RIGHT_TO_TERMINATE: "SAVE_AGREE_RIGHT_TO_TERMINATE",
  SAVE_ACTIVE_CODE: "SAVE_ACTIVE_CODE",
  CONSENT_VIBER: "CONSENT_VIBER",
  SAVE_AGREE_CONSENT_LANDING_PAGE_ENROLLMENT:
    "SAVE_AGREE_CONSENT_LANDING_PAGE_ENROLLMENT",
  SAVE_CONTACT_DETAILS: "SAVE_CONTACT_DETAILS",
  SET_ENROLLMENT_MODAL_IS_SHOWN: "SET_ENROLLMENT_MODAL_IS_SHOWN",
}

const wildcard = {
  SAVE: "SAVE",
}

const upload = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
  SAVE_ACTIVE_DOCUMENT: "SAVE_ACTIVE_DOCUMENT",
  REMOVE_ACTIVE_DOCUMENT: "REMOVE_ACTIVE_DOCUMENT",
}

const session = {
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

export const EnrollmentReducer = (state, action) => {
  switch (action.type) {
    case forms.SAVE_EMPLOYEE_INFORMATION:
      return { ...state, ...action.payload }

    case forms.SAVE_AGREE_CONSENT_ENROLLMENT:
      return { ...state, consentAndAuthorization: [...action.payload] }

    case forms.SAVE_AGREE_WITH_HYPERTENSION:
      return { ...state, isDiagnosedWithHypertension: [...action.payload] }

    case forms.SAVE_AGREE_ALLOW_ACCESS:
      return { ...state, allowAccessToResults: [...action.payload] }

    case forms.SAVE_AGREE_RIGHT_TO_TERMINATE:
      return { ...state, rightToTerminate: [...action.payload] }

    case upload.SAVE_DOCUMENTS:
      return {
        ...state,
        ...action.payload?.values,
        [action.payload?.name]: [
          ...(action.payload.documents || action.payload),
        ],
      }

    case upload.SAVE_ACTIVE_DOCUMENT:
      return {
        ...state,
        activeDocument: { ...action.payload },
      }

    case upload.REMOVE_ACTIVE_DOCUMENT:
      const { arrayName, ...newTempDocument } = action.payload
      const currentDocuments = state[arrayName] || []
      const updatedDocuments = currentDocuments.map((document) => {
        if (document.oldname === newTempDocument.oldname) {
          return { ...document, ...newTempDocument }
        }
        return document
      })
      return {
        ...state,
        activeDocument: null,
        [arrayName]: updatedDocuments,
      }

    case forms.SAVE_ACTIVE_CODE:
      return { ...state, activeCode: { ...action.payload } }

    case forms.CONSENT_VIBER:
      return { ...state, consentViber: action.payload }

    case forms.SAVE_AGREE_CONSENT_LANDING_PAGE_ENROLLMENT:
      return {
        ...state,
        consentLandingPage: [...action.payload],
      }

    case forms.SAVE_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.payload,
      }

    case forms.SET_ENROLLMENT_MODAL_IS_SHOWN: {
      return {
        ...state,
        existingEnrollmentModalIsShown: action.payload,
      }
    }

    case wildcard.SAVE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }

    case session.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ state: { ...toBeSaved }, stateKey })
      return toBeSaved

    case session.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession({ stateKey }) }
      return {
        ...state,
        ...returnState,
        modal: {
          ...initialState.modal,
        },
      }

    case session.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession({ stateKey })
      return {
        ...initialState,
      }

    case forms.RESET_DETAILS:
    default:
      return { ...state, ...state.initialState }
  }
}
