import React, { useReducer } from "react"

import { EnrollmentReducer } from "./EnrollmentReducer"
import { useAirtableEnrollmentFormFields } from "./hooks/useAirtableEnrollmentFormFields"

import { generateInitialValues } from "../../../services/context"
import enrollmentInitialState from "./utils/enrollmentInitialState"

let { flattenNode } = require("../../../services/arrays")

const EnrollmentContext = React.createContext()

const EnrollmentProvider = ({ children }) => {
  let formFields = useAirtableEnrollmentFormFields()
  formFields = flattenNode(formFields?.allAirtableFormFields)
  let [enrollmentState, enrollmentDispatch] = useReducer(EnrollmentReducer, {
    ...generateInitialValues({ fields: formFields || [] }),
    ...enrollmentInitialState,
    initialState: {
      ...generateInitialValues({ fields: formFields || [] }),
      ...enrollmentInitialState,
    },
  })

  return (
    <EnrollmentContext.Provider value={{ enrollmentState, enrollmentDispatch }}>
      {children}
    </EnrollmentContext.Provider>
  )
}

export { EnrollmentContext, EnrollmentProvider }
