import { useStaticQuery, graphql } from "gatsby"

const ALL_AIRTABLE_EPHARMACY_FIELDS_QUERY = graphql`
  query {
    allAirtableFormFields(
      filter: { data: { Product: { eq: "Epharmacy" } } }
    ) {
      nodes {
        data {
          Name
          Type
          Initial_Values
          Field_Names
        }
      }
    }
  }
`

export const useAirtableEpharmacyFormFields = () => {
  const data = useStaticQuery(ALL_AIRTABLE_EPHARMACY_FIELDS_QUERY)

  return data
}
