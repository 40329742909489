import { useStaticQuery, graphql } from "gatsby"

const ALL_AIRTABLE_FOLLOW_UP_LAB_TEST_FIELDS_QUERY = graphql`
  query {
    allAirtableFormFields(
      filter: { data: { Product: { eq: "Follow-up Laboratory Tests" } } }
    ) {
      nodes {
        data {
          Name
          Type
          Initial_Values
          Field_Names
        }
      }
    }
  }
`

export const useAirtableFollowUpLabTestFormFields = () => {
  const data = useStaticQuery(ALL_AIRTABLE_FOLLOW_UP_LAB_TEST_FIELDS_QUERY)

  return data
}
