import { isBrowser } from "../../services/general"
import { parse, stringify } from "flatted/esm"

export const getContextFromSession = ({ stateKey }) =>
  isBrowser() && sessionStorage.getItem(stateKey)
    ? parse(sessionStorage.getItem(stateKey))
    : {}

export const saveContextToSession = ({ state, stateKey }) => {
  if (isBrowser()) sessionStorage.setItem(stateKey, stringify({ ...state }))
}

export const removeContextFromSession = ({ stateKey }) => {
  if (isBrowser()) sessionStorage.removeItem(stateKey)
}
