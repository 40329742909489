import { useStaticQuery, graphql } from "gatsby"

const ALL_AIRTABLE_ENROLLMENT_FIELDS_QUERY = graphql`
  query {
    allAirtableFormFields(filter: { data: { Product: { eq: "Enrollment" } } }) {
      nodes {
        data {
          Name
          Type
          Initial_Values
          Field_Names
        }
      }
    }
  }
`

export const useAirtableEnrollmentFormFields = () => {
  const data = useStaticQuery(ALL_AIRTABLE_ENROLLMENT_FIELDS_QUERY)

  return data
}
