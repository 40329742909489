import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../../../context/services/context"

const stateKey = "epharmacyReducer"

export const initialState = {
  medicines: [],
  documents: [],
  uploadPrescription: [],
  activeDocument: {},
  coupon: null,
  consentAndAuthorization: [],
  isEnrolled: false,
}

const wildcard = {
  SET_KEY: "SET_KEY",
}

const forms = {
  SAVE_EMPLOYEE_INFORMATION: "SAVE_EMPLOYEE_INFORMATION",
  RESET_DETAILS: "RESET_DETAILS",
  SAVE_AGREE_CONSENT_EPHARMACY: "SAVE_AGREE_CONSENT_EPHARMACY",
}

const upload = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
  SAVE_ACTIVE_DOCUMENT: "SAVE_ACTIVE_DOCUMENT",
  REMOVE_ACTIVE_DOCUMENT: "REMOVE_ACTIVE_DOCUMENT",
}

const epharmacy = {
  SAVE_MEDICINES: "SAVE_MEDICINES",
}

const session = {
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

export const EpharmacyReducer = (state, action) => {
  switch (action.type) {
    case forms.SAVE_EMPLOYEE_INFORMATION:
      return { ...state, ...action.payload }

    case forms.SAVE_AGREE_CONSENT_EPHARMACY:
      return { ...state, consentAndAuthorization: [...action.payload] }

    case upload.SAVE_DOCUMENTS:
      return {
        ...state,
        ...action.payload?.values,
        [action.payload?.name]: [
          ...(action.payload.documents || action.payload),
        ],
      }

    case upload.SAVE_ACTIVE_DOCUMENT:
      return {
        ...state,
        activeDocument: { ...action.payload },
      }

    case upload.REMOVE_ACTIVE_DOCUMENT:
      const { arrayName, ...newTempDocument } = action.payload
      const currentDocuments = state[arrayName] || []
      const updatedDocuments = currentDocuments.map((document) => {
        if (document.oldname === newTempDocument.oldname) {
          return { ...document, ...newTempDocument }
        }
        return document
      })
      return {
        ...state,
        activeDocument: null,
        [arrayName]: updatedDocuments,
      }

    case epharmacy.SAVE_MEDICINES:
      return { ...state, medicines: [...action.payload] }

    case session.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ state: { ...toBeSaved }, stateKey })
      return toBeSaved

    case session.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession({ stateKey }) }
      return {
        ...state,
        ...returnState,
        modal: {
          ...initialState.modal,
        },
      }

    case session.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession({ stateKey })
      return {
        ...initialState,
      }

    case wildcard.SET_KEY:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }

    case forms.RESET_DETAILS:
    default:
      return { ...state, ...state.initialState }
  }
}
