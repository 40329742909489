import React, { useReducer } from "react"

import epharmacyInitialState from "./utils/epharmacyInitialState"

import { EpharmacyReducer } from "./EpharmacyReducer"
import { useAirtableEpharmacyFormFields } from "./hooks/useAirtableEpharmacyFormFields"
import { generateInitialValues } from "../../../services/context"

let { flattenNode } = require("../../../services/arrays")

const EpharmacyContext = React.createContext()

const EpharmacyProvider = ({ children }) => {
  let formFields = useAirtableEpharmacyFormFields()
  formFields = flattenNode(formFields?.allAirtableFormFields)
  let [epharmacyState, epharmacyDispatch] = useReducer(EpharmacyReducer, {
    ...generateInitialValues({ fields: formFields || [] }),
    ...epharmacyInitialState,
    initialState: {
      ...generateInitialValues({ fields: formFields || [] }),
      ...epharmacyInitialState,
    },
  })

  return (
    <EpharmacyContext.Provider value={{ epharmacyState, epharmacyDispatch }}>
      {children}
    </EpharmacyContext.Provider>
  )
}

export { EpharmacyContext, EpharmacyProvider }
