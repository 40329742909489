import React, { useReducer } from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

import AppTheme from "./AppTheme"
import Toast from "../components/Layout/Toast"
import { Modal } from "@MedGrocer/components"

import { initialState } from "./initialState"
import { AppReducer } from "./AppReducer"

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Modal
        active={state.modal.isActive}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        className={state.modal.class}
        headerClassName={state.modal.headerClass}
        hideCloseButton={state.modal.hideCloseButton}
        closeOnClickOutside={state.modal.closeOnClickOutside}
      >
        {state.modal.content}
      </Modal>
      <Toast
        isActive={state.toast.isActive}
        message={state.toast.message}
        color={state.toast.color}
      />
      <ThemeToggler>{({ theme }) => <AppTheme theme={theme} />}</ThemeToggler>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
