export const generateInitialValue = ({ field }) => {
  let initialValues = {}

  switch (field.type) {
    case "checkbox":
    case "multiselect":
    case "upload":
      initialValues[field.name] = []
      break

    case "number":
      initialValues[field.name] = ""
      break

    case "schedule":
      initialValues[field.name] = {}
      for (let fieldName of field.fieldNames) {
        initialValues[field.name][fieldName] = ""
      }
      break

    case "hospital":
    case "select":
    case "height":
    case "weight":
      initialValues[field.name] = {
        label: field.placeholder,
        value: "",
      }
      break

    case "date":
      initialValues[field.name] = {
        month: {
          label: "MMM",
          value: "",
        },
        date: {
          label: "DD",
          value: "",
        },
        year: {
          label: "YYYY",
          value: "",
        },
      }
      break

    case "address":
      let addressInitialValues = {}
      if (field.initialValues) {
        addressInitialValues = JSON.parse(field.initialValues)
      }
      initialValues[field.name] = {
        city: addressInitialValues.city || {
          label: "",
          value: "",
        },
        barangay: addressInitialValues.barangay || {
          label: "",
          value: "",
        },
        province: addressInitialValues.province || {
          label: "",
          value: "",
        },
        siteName: addressInitialValues.siteName || {
          label: "",
          value: "",
        },
        houseNumber: addressInitialValues.houseNumber || "",
        streetAddress: addressInitialValues.streetAddress || "",
        addressType: addressInitialValues.addressType || "",
        notes: "",
      }
      break

    default:
      initialValues[field.name] = field.initialValues || ""
      break
  }

  return initialValues
}

export const generateInitialValues = ({ fields }) => {
  let initialValues = {}

  for (let field of fields) {
    let generatedInitialValue = {
      ...generateInitialValue({ field }),
    }
    initialValues = {
      ...initialValues,
      [field.name]:
        initialValues?.[field?.name] || generatedInitialValue?.[field.name],
    }
  }

  return initialValues
}
