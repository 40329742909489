import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../../../context/services/context"

const stateKey = "followUpLabTestState"

export const initialState = {
  consentAndAuthorization: [],
}

const forms = {
  SAVE_EMPLOYEE_INFORMATION: "SAVE_EMPLOYEE_INFORMATION",
  RESET_DETAILS: "RESET_DETAILS",
  SAVE_AGREE_CONSENT_FOLLOW_UP_LAB_TEST:
    "SAVE_AGREE_CONSENT_FOLLOW_UP_LAB_TEST",
}

const session = {
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

export const FollowUpLabTestReducer = (state, action) => {
  switch (action.type) {
    case forms.SAVE_EMPLOYEE_INFORMATION:
      return { ...state, ...action.payload }

    case forms.SAVE_AGREE_CONSENT_FOLLOW_UP_LAB_TEST:
      return { ...state, consentAndAuthorization: [...action.payload] }

    case session.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ state: { ...toBeSaved }, stateKey })
      return toBeSaved

    case session.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession({ stateKey }) }
      return {
        ...state,
        ...returnState,
      }

    case session.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession({ stateKey })
      return {
        ...initialState,
      }

    case forms.RESET_DETAILS:
    default:
      return { ...state, ...state.initialState }
  }
}
