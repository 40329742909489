import React, { useReducer } from "react"

import { FollowUpLabTestReducer } from "./FollowUpLabTestReducer"
import { useAirtableFollowUpLabTestFormFields } from "./hooks/useAirtableFollowUpLabTestFormFields"

import { generateInitialValues } from "../../../services/context"
import followUpLabTestInitialState from "./utils/followUpLabTestInitialState"

let { flattenNode } = require("../../../services/arrays")

const FollowUpLabTestContext = React.createContext()

const FollowUpLabTestProvider = ({ children }) => {
  let formFields = useAirtableFollowUpLabTestFormFields()
  formFields = flattenNode(formFields?.allAirtableFormFields)
  let [followUpLabTestState, followUpLabTestDispatch] = useReducer(FollowUpLabTestReducer, {
    ...generateInitialValues({ fields: formFields || [] }),
    ...followUpLabTestInitialState,
    initialState: {
      ...generateInitialValues({ fields: formFields || [] }),
      ...followUpLabTestInitialState,
    },
  })

  return (
    <FollowUpLabTestContext.Provider value={{ followUpLabTestState, followUpLabTestDispatch }}>
      {children}
    </FollowUpLabTestContext.Provider>
  )
}

export { FollowUpLabTestContext, FollowUpLabTestProvider }
