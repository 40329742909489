import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/services/apollo"

import { AppProvider } from "./src/context/AppContext"
import { EnrollmentProvider } from "./src/components/Enrollment/EnrollmentContext/EnrollmentContext"
import { FollowUpLabTestProvider } from "./src/components/FollowUpLabTest/FollowUpLabTestContext/FollowUpLabTestContext"
import { EpharmacyProvider } from "./src/components/Epharmacy/EpharmacyContext/EpharmacyContext"
import "./static/styles/global.scss"
import "./static/styles/global.css"

export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <EnrollmentProvider>
        <FollowUpLabTestProvider>
          <EpharmacyProvider>
            <ApolloProvider client={client}>{element}</ApolloProvider>
          </EpharmacyProvider>
        </FollowUpLabTestProvider>
      </EnrollmentProvider>
    </AppProvider>
  )
}
