export const initialState = {
  documents: [],
  medicines: [],
  auth: {
    email: "",
    password: "",
    confirmPassword: "",
    hasBeenVerified: false,
  },
  enrollment: {
    agreeToConsent: [],
    // employee: {
    firstName: "",
    lastName: "",
    birthday: {
      month: {
        label: "",
        value: "",
      },
      date: {
        label: "",
        value: "",
      },
      year: "",
    },
    gender: "",
    mobileNumber: "",
    employeeNumber: "",
    site: {
      label: "",
      value: "",
    },
    department: "",
    address: {
      addressType: "Home",
      houseNumber: "",
      streetAddress: "",
      province: {
        label: "",
        value: "",
      },
      city: {
        label: "",
        value: "",
      },
    },
    // },
    // emergencyContact: {
    emergencyContactName: "",
    emergencyContactMobileNumber: "",
    emergencyContactRelationship: "",
    // },
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    class: null,
    headerClass: null,
    hideCloseButton: false,
    closeOnClickOutside: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  agreeToConsent: {
    medEnsure: [],
    flexmedCashless: [],
  },
  isDark: false,
  hasShownDarkModal: false,
  slidingModal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    isClosed: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    closeOnClickOutside: false,
    background: {
      color: null,
      opacity: "100",
    },
    isCloseable: true,
  },
}
